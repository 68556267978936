import React, { Component } from 'react';
import './JobsHistory.css';

class JobsHistory extends Component {

  render() {
    return (
      <div className="jobs-history">
      </div>
    );
  }
}

export default JobsHistory;
